import { Component, inject, Inject } from '@angular/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss'],
  standalone: true,
})
export class DownloadAppComponent {
  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  constructor(@Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string }) {}

  onDownloadAppClicked(): void {
    this._logMixpanelEventUseCase.execute({ eventName: 'wallet_click_download_app' });
  }
}
