<section class="container--taagerTeal">
  <p>للتمكن من متابعة لعبة الطموح يرجى استخدام تطبيق تاجر</p>
  <div class="runner-contianer">
    <div class="img-contianer">
      <img
        loading="lazy"
        _ngcontent-pfn-c152=""
        src="assets/img/rewards-program-icons/coin.svg"
        alt="coin.svg"
        class="coin"
      />
      <img loading="lazy" src="assets/img/rewards-program-icons/runner.svg" alt="" class="runner" />
    </div>
    <div class="runner-progress"></div>
  </div>
  <a
    [href]="appURLs.ANDROID_APP_URL"
    class="mobile-app-banner__link"
    (click)="onDownloadAppClicked()"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img
      loading="lazy"
      class="mobile-app-banner__google-play-button"
      src="assets/img/google-play-badge.webp"
      alt="Google Play button"
    />
  </a>
</section>
