import { DateWrapperUtility } from './date-wrapper.utility';

export const PREVIOUS_MONTH_ARABIC_NAME = DateWrapperUtility.periodNLocaleName(
  -1,
  'month',
  'ar',
  'MMMM',
);
export const CURRENT_MONTH_ARABIC_NAME = DateWrapperUtility.periodNLocaleName(
  0,
  'month',
  'ar',
  'MMMM',
);
export const NEXT_MONTH_ARABIC_NAME = DateWrapperUtility.periodNLocaleName(
  1,
  'month',
  'ar',
  'MMMM',
);
